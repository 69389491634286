import Button from './Button';
import cat_dancer from './images/cat_dancer.png';
import logo_head from './images/logo_head.png';
import { useState, useEffect } from 'react';
import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import contract from './contract/CatNFT.json';
import meow from './audio/meow.wav';



function App(props) {
  const AUDIO = new Audio(meow);
  const API_URL = process.env.REACT_APP_API_URL;
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  const web3 = createAlchemyWeb3(API_URL);
  const nftContract = new web3.eth.Contract(contract.abi, contractAddress);
  const { useEthers, useEtherBalance, formatEther } = props;
  const [headline, setHeadline] = useState('');
  const [catMint, setCatmint] = useState('Mint a cool cat');
  const CTA = 'Connect MetaMask!';
  const { activateBrowserWallet, account } = useEthers();
  const [hash, setHash] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [connectMessage, setConnectMessage] = useState('');
  const [error, setError] = useState('');
  const etherBalance = useEtherBalance(account);

  useEffect(() => {
      const HEADLINE_ARRAY = [
    '500 OF THE COOLEST CATS ON THE ETHEREUM BLOCKCHAIN',
    'A Kool Katalog of Kool...Kats 🐱',
    'In Soviet Russia, The Onchain Pet Pets You 🥸',
    'Mint a Cat, Tweet About It, Be 🥶 Forever',
    'Meow Meow Meow? Meow! Meow Meow Meowwww. Good Chat! (That\'s \'cat\' in French)',
    'First time minting an NFT? Hit that \'Connect MetaMask\' button!',
    'Fun fact: the phrase \'YOLO\' does not apply to cats'
  ];
    setHeadline(HEADLINE_ARRAY[Math.random() * HEADLINE_ARRAY.length | 0]);
   }
    , [])

  const handleConnect = () => {
    if (window.web3) {
      setConnectMessage('');
      activateBrowserWallet();
    }
    else {
      console.log('hey');
      setConnectMessage('Please install MetaMask to collect your cat');
    }
  }

  const handleMint = async () => {
    if(disabled) return;
    AUDIO.play();
    setCatmint('Minting...');
    setDisabled(true);
    const nonce = await web3.eth.getTransactionCount(account, "latest")
      .catch(err => console.log(err))

    nftContract.methods.mintNFT(account).send({
      from: account,
      to: contractAddress,
      nonce,
      gas: '2100000',
    }, function (err, transactionHash) {
      if (err) {
        setDisabled(false);
        setCatmint('Try again? 🐈');
      }
      else {
        setCatmint('Miaow');
        setError('');
        return setHash(transactionHash);
      }
    }
    ).catch((err) => {
      setError('No gas?')
      console.error(err);
    });
}
  return (
    <div className="centerer">
      <header>
        {account && <div className="address">Wallet: {account.substr(0, 6)}...{account.substr(38, 5)}</div>}
        <div className="tooltip">
        <img src={logo_head} alt="logo" className="logo" />
          <span className="tooltiptext">
            <a
            href="https://bleeping.notion.site/Minting-a-Cool-Cat-For-Beginners-f9c07e5f5b0f494593f6f472976fb2a1"
            target="_blank"
              rel="noopener noreferrer">
              Problems with MetaMask? Click me for help!
            </a>
          </span>
        </div>
        {etherBalance && <div className="balance">Balance: {formatEther(etherBalance).substr(0,6)} ETH</div>}
      </header>
      <h1>{headline}</h1>
      <figure>
        <img className="cat_dancer" src={cat_dancer} alt="cat_dancer" />
      </figure>
      <div className="space">
        {hash &&
          <div>
          <p>Your Cool Cat is minting. Head on over to <a href={`https://rinkeby.etherscan.io/tx/${hash}`} target="_blank"
              rel="noopener noreferrer">Rinkeby Etherscan </a> to see how they're doing!</p>
          <p>When the mint is successful, you'll find your NFT on your <a href={`https://testnets.opensea.io/${account}?search[sortBy]=LAST_TRANSFER_DATE&search[sortAscending]=false`} target="_blank"
              rel="noopener noreferrer">OpenSea Profile here</a>.</p>
            <p>Alternatively, you can <a href={`https://rinkeby.rarible.com/user/${account}/owned`} rel="noopener noreferrer">find your NFT on Rarible</a></p>
            <p>PS: the Ethereum blockchain is quite slow, so if you don't find your NFT when you visit those links, <br/>just wait for a few minutes and try again. I recommend five minutes :D</p>
          </div>
        }
        {error && <p>{error} Try getting <a href ="https://app.mycrypto.com/faucet" target="_blank" rel="noopener noreferrer">some from a faucet.</a></p>}
      {connectMessage &&
        <a href="https://metamask.io/" target="_blank" rel="noopener noreferrer"><p>
          {connectMessage}
        </p></a>}
      </div>
      <Button
        account={account}
        cta={account ? catMint : CTA}
        handleClick={!account
          ? handleConnect
          : handleMint}
        disabled={disabled} />
      <p>First time minting an NFT? <a
            href="https://bleeping.notion.site/Minting-a-Cool-Cat-For-Beginners-f9c07e5f5b0f494593f6f472976fb2a1"
            target="_blank"
        rel="noopener noreferrer">Click me</a> for directions!</p>
      <p>Visiting this site on mobile? Make sure you open it on the Metamask browser and not your phone browser!</p>
      </div>
  )
}

export default App;
