import fox from './images/fox.svg';
function Button (props) {
    return (
        <button onClick={props.handleClick} id={props.disabled ? 'disabled': null}>
        {!props.account && <img className="fox" src={fox} alt="fox" />}
        {props.cta}
    </button>
)
}

export default Button;